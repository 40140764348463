
import {defineComponent} from "vue";
import {HourglassOutlined, UserOutlined} from '@ant-design/icons-vue'

export default defineComponent({
  name: "TimetableCard",
  props: {
    className: {
      type: String,
      required: false,
      default: '美学探索初级1班'
    },
    classTime: {
      type: String,
      required: false,
      default: '星期五 10：00-11:00'
    },
    classProgress: {
      type: String,
      required: false,
      default: '0/18课次'
    },
    studentNum: {
      type: String,
      required: false,
      default: '18人'
    },
    isOther: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    UserOutlined,
    HourglassOutlined
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
