
import {defineComponent} from "vue";

export default defineComponent({
  name: "TimetableDisplay",
  props:{
    title: {
      required: false,
      default: '今日课程',
      type: String,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
